.more-projects {
  .zoom-img {
    position: relative;
    display: block;
    overflow: hidden;
    margin-bottom: 20px;
    transition: .3s all ease;

    img {
      object-fit: cover;
    }

    &:hover {
      transform: scale(1.05);
    }
  }
}