// MENU
.position-relative {
  position: relative;
}

.logo {
  font-size: 1.6rem;
  @include media-breakpoint-down(md) {
    font-size: 1.4rem;
  }

  img {
    width: 38px;
    height: 38px;
  }

  color: $cta-pink;
  font-weight: 700;

  &:hover {
    color: $cta-pink;
  }
}

.offcanvas {
  --bs-offcanvas-height: 315px;
}

.navbar-offcanvas {
  @include media-breakpoint-down(md) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.navbar {
  box-shadow: 0 4px 4px rgba(9, 32, 66, 0.1);

  .nav-item {
    font-weight: 700;
    @include media-breakpoint-up(md) {
      border-bottom: 3px solid transparent;
    }

    &.active {
      @include media-breakpoint-up(md) {
        border-bottom: 3px solid $primary;
      }

      a.nav-link {
        color: $primary;
      }
    }

    a.nav-link {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.75rem 0;
      font-size: 18px;
      color: $black;
      height: 100%;

      &:hover {
        color: $cta-pink;
      }
    }
  }
}