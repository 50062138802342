.about-me {
  .unit-4 h3 {
    font-family: $font-family-sans-serif-secondary;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    letter-spacing: .04rem;
  }
  .design-process {
    p {
      font-size: 16px;
    }
  }
}

.my-background {
  h3 {
    border-bottom: 1px solid $black;
    font-family: $font-family-sans-serif-secondary;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 12px;
    text-transform: uppercase;
  }
  h5 {
    font-family: $font-family-sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
  }
  .education-item {
    h6 {
      font-family: $font-family-sans-serif-secondary;
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 0;
    }
    span {
      font-family: $font-family-sans-serif-secondary;
      font-size: 14px;
      line-height: 18px;
    }
    .course {
      margin-left: 12%;
    }
  }
}