$primary: #ff4c4c;
$secondary: #52565e;
$black: #1e2036;
$cta-pink: #f62459;
$carousel-control-prev-icon-bg: url('data:image/svg+xml;utf-8,<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">    <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" stroke="#{$black}"/>    <g clip-path="url(#clip0_403_1593)">        <path d="M27.5098 13.8701L25.7298 12.1001L15.8398 22.0001L25.7398 31.9001L27.5098 30.1301L19.3798 22.0001L27.5098 13.8701V13.8701Z"              fill="#{$black}"/>    </g>    <defs>        <clipPath id="clip0_403_1593">            <rect width="24" height="24" fill="white" transform="translate(10, 10)"/>        </clipPath>    </defs></svg>');
$carousel-control-next-icon-bg: url('data:image/svg+xml;utf-8,<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">    <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" stroke="#{$black}"/>    <g clip-path="url(#clip0_403_1541)">        <path d="M16.4902 30.1301L18.2602 31.9001L28.1602 22.0001L18.2602 12.1001L16.4902 13.8701L24.6202 22.0001L16.4902 30.1301V30.1301Z"              fill="#{$black}"/>    </g>    <defs>        <clipPath id="clip0_403_1541">            <rect width="24" height="24" fill="white" transform="translate(10, 10)"/>        </clipPath>    </defs></svg>');
$carousel-control-icon-width: 44px;
$carousel-control-color: $cta-pink;
$carousel-indicator-active-bg: $cta-pink;
$carousel-indicator-width: 8px;
$carousel-indicator-height: 8px;

$font-family-sans-serif: 'Playfair Display', sans-serif;
$font-family-sans-serif-secondary: 'Roboto', sans-serif;
$enable-negative-margins: true;

@import 'node_modules/bootstrap/scss/bootstrap';

@import 'components/site-common';
@import 'components/site-about-me';
@import 'components/testimonials';
@import 'components/site-case-studies';
@import 'components/site-more-projects';
@import 'components/site-navbar';
@import 'components/site-blocks';
@import 'components/site-footer';
