.carousel-control-next, .carousel-control-prev {
  opacity: 1;
  width: 20%;

  button {
    background: none;
    border: 0;
  }

  &:hover {
    opacity: 1;
  }
}

.carousel-control-prev button {
  &:hover {
    .carousel-control-prev-icon {
      background-image: url('data:image/svg+xml;utf-8,<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">    <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" stroke="#{$cta-pink}"/>    <g clip-path="url(#clip0_403_1593)">        <path d="M27.5098 13.8701L25.7298 12.1001L15.8398 22.0001L25.7398 31.9001L27.5098 30.1301L19.3798 22.0001L27.5098 13.8701V13.8701Z"              fill="#{$cta-pink}"/>    </g>    <defs>        <clipPath id="clip0_403_1593">            <rect width="24" height="24" fill="white" transform="translate(10, 10)"/>        </clipPath>    </defs></svg>');
    }
  }
}

.carousel-control-next button {
  &:hover {
    .carousel-control-next-icon {
      background-image: url('data:image/svg+xml;utf-8,<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">    <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" stroke="#{$cta-pink}"/>    <g clip-path="url(#clip0_403_1541)">        <path d="M16.4902 30.1301L18.2602 31.9001L28.1602 22.0001L18.2602 12.1001L16.4902 13.8701L24.6202 22.0001L16.4902 30.1301V30.1301Z"              fill="#{$cta-pink}"/>    </g>    <defs>        <clipPath id="clip0_403_1541">            <rect width="24" height="24" fill="white" transform="translate(10, 10)"/>        </clipPath>    </defs></svg>');
    }
  }
}

.carousel-indicators {
  margin-bottom: -2rem;

  [data-bs-target] {
    border-radius: 8px;
    border: 4px solid transparent;
  }
}

.carousel-inner {
  margin: 0 auto;
  @include media-breakpoint-up(md) {
    max-width: 65%;
  }
}

.carousel-item {

  .carousel-caption {
    position: relative;
    left: 0;
    right: 0;
  }

  .testimonial {
    background: $white;
    padding: 36px 64px 18px 64px;
    @include media-breakpoint-down(md) {
      padding: 36px 32px 18px 32px;
    }
    border-radius: 4px;

    .quote {
      font-size: 70px;
      line-height: 70px;
      letter-spacing: -4px;
      position: relative;
      color: $black;
      font-family: 'Times New Roman', sans-serif;
    }

    blockquote {
      margin-top: -38px;
      font-size: 18px;
      text-indent: 40px;
      margin-bottom: 24px;

      p {
        color: $black;
      }
    }
  }

  .author {
    margin: 0 auto;
    display: flex;

    > .pic {
      flex: 0 0 60px;
      float: left;
      width: 60px;
      height: 60px;

      img {
        border-radius: 50%;
      }
    }

    > .author-name {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;
      margin-left: 12px;

      h5 {
        font-size: 16px;
        margin-bottom: 0;
        color: $black;
      }

      span {
        font-size: 14px;
        color: rgba($black, .7);
      }
    }
  }
}