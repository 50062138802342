body {
  font-family: $font-family-sans-serif-secondary;
  font-weight: 300;
  overflow-x: hidden;
  font-size: 18px;
  color: $black;
}

h1, h2, h3, h4, h5, h6 {;
  color: $black;
}

h1, h2, h3 {
  font-family: 'Playfair Display', serif;
  font-weight: 700
}

.my-expertise h3 {
  font-family: inherit;
  font-weight: 400;
}

.text-black {
  color: $black !important;
}

a {
  transition: .3s all ease;
  text-decoration: none;
  color: $primary;

  &:hover {
    color: $primary;
  }
}

#btn-back-to-top {
  border: 0;
  background: transparent;
  position: fixed;
  bottom: 40px;
  right: 20px;
  display: none;
}


.hero, .bg-image {
  background-color: $white;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  @include media-breakpoint-up(lg) {
    margin-top: 54px;
    min-height: 650px;
  }
  @include media-breakpoint-down(lg) {
    margin-top: 48px;
    margin-bottom: 0;
  }
  overflow: hidden;
  object-fit: cover;

  .img-bg {
    position: absolute;
    @include media-breakpoint-up(xxl) {
      transform: scale(1.2) translateY(-25%) translateX(5%);
    }
    @include media-breakpoint-down(xxl) {
      transform: scale(1.4) translateY(-7%) translateX(5%);
    }
    @include media-breakpoint-down(xl) {
      transform: scale(1.5) translateY(7%) translateX(5%);
    }
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  .img-bg-mobile {
    z-index: 2;
  }

  .sphair {
    position: absolute;
    @include media-breakpoint-up(xxl) {
      transform: scale(1.2) translateY(-10%) translateX(15%);
    }
    @include media-breakpoint-down(xxl) {
      transform: scale(1.2) translateY(-2%) translateX(15%);
    }
    @include media-breakpoint-down(xl) {
      transform: scale(1.3) translateY(15%) translateX(10%);
    }
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  .hero-main-text {
    @include media-breakpoint-up(xxl) {
      padding-top: 200px;
    }
    @include media-breakpoint-down(xxl) {
      padding-top: 200px;
    }
    @include media-breakpoint-down(lg) {
      padding-top: 100px;
    }
  }

  .hero-subtitle {
    font-size: 24px;
    color: $black;
    display: block;
    margin-bottom: 50px;
    
    @include media-breakpoint-down(lg) {
      margin-bottom: 1rem;
    }
  }

  &.overlay {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      @include media-breakpoint-up(xxl) {
        background: linear-gradient(130deg, white, white 50%, transparent 40%, transparent), rgba($black, .2);
      }
      @include media-breakpoint-down(xxl) {
        background: linear-gradient(125deg, white, white 53%, transparent 40%, transparent), rgba($black, .2);
      }
      @include media-breakpoint-down(xl) {
        background: linear-gradient(113deg, white, white 60%, transparent 40%, transparent), rgba($black, .2);
      }
      @include media-breakpoint-down(lg) {
        background: transparent;
      }
    }

    &.light:before {
      @include media-breakpoint-up(xxl) {
        background: linear-gradient(130deg, white, white 50%, transparent 40%, transparent), rgba($black, .1);
      }
      @include media-breakpoint-down(xxl) {
        background: linear-gradient(125deg, white, white 53%, transparent 40%, transparent), rgba($black, .1);
      }
      @include media-breakpoint-down(xl) {
        background: linear-gradient(113deg, white, white 60%, transparent 40%, transparent), rgba($black, .1);
      }
      @include media-breakpoint-down(lg) {
        background: transparent;
      }
    }

    > .container {
      position: relative;
      z-index: 2;
    }
  }

  svg {
    position: absolute;
    bottom: 0;
    left: 0;
  }

}

.heading-2 {
  font-size: 56px;
  font-weight: 700;
  line-height: 1.3;
  font-family: 'Playfair Display', serif;
  @include media-breakpoint-down(lg) {
    font-size: 56px;
  }
  @include media-breakpoint-down(lg) {
    font-size: 40px;
  }
}

.heading {
  font-size: 80px;
  line-height: 1.2;
  font-weight: 700;
  @include media-breakpoint-down(lg) {
    font-size: 60px;
  }
  @include media-breakpoint-down(lg) {
    font-size: 40px;
  }
}

.subheading {
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: .1rem;
  line-height: 1rem;
  font-weight: 700;
  color: #4d4d4d;
}

.v-h-full {
  height: 100vh;
  min-height: 700px;
  @include media-breakpoint-down(md) {
    height: auto;
    min-height: auto;
    padding-top: 7rem;
    padding-bottom: 2rem;
  }
}

.section {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.bg-darker {
  background: #092046;
  color: $white;

  h2, h3, h4, h5 {
    color: $white;
  }

  h5 {
    font-weight: 600;
  }
}

.number {
  font-size: 70px;
  font-weight: 700;
}


.btn {
  font-family: $font-family-sans-serif-secondary;
  border-radius: 0;
  padding: 14px 30px;

  &:hover, &:active, &:focus {
    box-shadow: none;
    outline: none;
  }

  &.btn-primary-outline {
    color: $black;
    border-width: 2px;
    border-color: $black;

    &.white {
      color: $white;
      border-color: rgba($white, 1);
    }

    &.btn-nav-bar {
      padding: 8px 16px;
      margin: 0;
    }

    &:hover {
      background: $black;
      color: $white;
    }
  }

  &.btn-black {
    background-color: #222;
    border: 2px solid transparent;
    color: $white;
    padding: 14px 30px;

    &:hover {
      color: $black;
      border-color: $black;
      background-color: transparent;
    }
  }
}

.custom-alert {
  display: none;
}

.section-contact {
  background-color: #efefef;
  z-index: 9;
  position: relative;
  @include media-breakpoint-down(md) {
    margin-top: 0 !important;
  }
}

.form-contact {

  .form-group {
    margin-bottom: 30px;
  }

  .form-control {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;

    &:hover, &:active, &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.footer {
  font-size: 16px;
}

// PRELOADER STYLE
#overlayer {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 7100;
  background: $white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.loader {
  z-index: 7700;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.heading-3 {
  font-size: 28px;
  color: #808080;
  line-height: 42px;
  font-weight: 300 !important;
}

.more {
  position: relative;
  display: inline-block;
  padding-bottom: 7px;

  &:before, &:after {
    position: absolute;
    width: 100%;
    content: "";
    height: 1px;
    background-color: #ccc;
    bottom: 0;
    transition: .3s all ease;
    left: 0;
  }

  &:after {
    transform: scale(0.0);
    width: 0;
    background-color: $black;
  }

  &:hover {
    &:after {
      width: 100%;
      transform: scale(1.0);
    }
  }
}


.ul-check {
  margin-bottom: 50px;

  li {
    position: relative;
    padding-left: 35px;
    margin-bottom: 15px;
    line-height: 1.5;

    &:before {
      left: 0;
      font-size: 20px;
      top: -.3rem;
      font-family: "icomoon";
      content: "\e5ca";
      position: absolute;
    }
  }

  &.white {
    li {
      &:before {
        color: $white;
      }
    }
  }

  &.success {
    li {
      &:before {
        color: $success;
      }
    }
  }

  &.primary {
    li {
      &:before {
        color: $primary;
      }
    }
  }
}

.relative {
  position: relative !important;
}

.responsibilities {
  .base-line {
    @include media-breakpoint-down(xl) {
      display: none;
    }
    background: linear-gradient(270deg, #1076C6 56.22%, rgba(37, 139, 218, 0) 100%);
    border-radius: 4px;
    height: 4px;

    .step {
      display: block;
      width: 24px;
      height: 24px;
      border: 5px solid white;
      border-radius: 12px;
      margin-top: -10px;
    }

    .step-1 {
      background: #e6ebf2;
    }

    .step-2 {
      background: #b2c2d6;
    }

    .step-3 {
      background: #b2c2d6;
    }

    .step-4 {
      background: #006fc5;
    }

    .step-5 {
      background: #006fc5;
    }

    .step-6 {
      background: #006fc5;
    }
  }

  .key-actions {
    color: #1076C6;
  }

  ul {
    padding: 0;
    list-style-type: none;

    li {
      margin-bottom: .5rem;
    }
  }

  .cs-role-item {
    @include media-breakpoint-up(xl) {
      min-height: 156px;
    }
  }
}

.design-thinking {
  .design-thinking-container {
    border-right: 1px solid #1076C6;
  }

  .step-container {
    padding: 1.5rem;
    margin: 1rem;
    border: 1px solid #C3DFF5;
    border-radius: 4px;

    .step {
      font-size: 1rem;
      text-align: center;
      padding-top: 0.6%;
      display: block;
      width: 26px;
      height: 26px;
      background: #e6ebf2;
      border-radius: 47px;
      float: left;
      margin-right: 0.5rem;
    }
  }

  h5 {
    color: #1076C6;
  }
}

.results {
  .results-screens-container {
    border-right: 1px solid #1076C6;

    .results-screens-number {
      display: block;
      color: #1076C6;
      font-size: 2rem;
      font-weight: 400;
    }

    .results-screens {
      display: block;
      color: #1076C6;
      font-size: 1.5rem;
      font-weight: 400;
    }
  }
}

.challenge-box {
  padding: 1rem;
  margin-bottom: 2rem;
  border-radius: 4px;

  &.business-requirements {
    border: 1px solid #FF7A00;
    background-color: #FFF8F2;

    .business-requirements-icon {
      display: inline-block;
      background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyOCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExLjM3NSA5LjIzMDc3SDcuODc1QzcuMzgyODEgOS4yMzA3NyA3IDkuNjkyMzEgNyAxMC4xNTM4VjEwLjYxNTRIMy45Mzc1VjUuNTM4NDZINC4zNzVDNC44MTI1IDUuNTM4NDYgNS4yNSA1LjEzNDYyIDUuMjUgNC42MTUzOFYwLjkyMzA3N0M1LjI1IDAuNDYxNTM4IDQuODEyNSAwIDQuMzc1IDBIMC44NzVDMC4zODI4MTIgMCAwIDAuNDYxNTM4IDAgMC45MjMwNzdWNC42MTUzOEMwIDUuMTM0NjIgMC4zODI4MTIgNS41Mzg0NiAwLjg3NSA1LjUzODQ2SDEuMzEyNVYxOS4zODQ2QzEuMzEyNSAyMS4xNzMxIDIuNjc5NjkgMjIuNjE1NCA0LjM3NSAyMi42MTU0SDdWMjMuMDc2OUM3IDIzLjU5NjIgNy4zODI4MSAyNCA3Ljg3NSAyNEgxMS4zNzVDMTEuODEyNSAyNCAxMi4yNSAyMy41OTYyIDEyLjI1IDIzLjA3NjlWMTkuMzg0NkMxMi4yNSAxOC45MjMxIDExLjgxMjUgMTguNDYxNSAxMS4zNzUgMTguNDYxNUg3Ljg3NUM3LjM4MjgxIDE4LjQ2MTUgNyAxOC45MjMxIDcgMTkuMzg0NlYxOS44NDYySDQuMzc1QzQuMTAxNTYgMTkuODQ2MiAzLjkzNzUgMTkuNjczMSAzLjkzNzUgMTkuMzg0NlYxMy4zODQ2SDdWMTMuODQ2MkM3IDE0LjM2NTQgNy4zODI4MSAxNC43NjkyIDcuODc1IDE0Ljc2OTJIMTEuMzc1QzExLjgxMjUgMTQuNzY5MiAxMi4yNSAxNC4zNjU0IDEyLjI1IDEzLjg0NjJWMTAuMTUzOEMxMi4yNSA5LjY5MjMxIDExLjgxMjUgOS4yMzA3NyAxMS4zNzUgOS4yMzA3N1pNMTAuNSA0LjYxNTM4SDI2LjI1QzI3LjE3OTcgNC42MTUzOCAyOCAzLjgwNzY5IDI4IDIuNzY5MjNDMjggMS43ODg0NiAyNy4xNzk3IDAuOTIzMDc3IDI2LjI1IDAuOTIzMDc3SDEwLjVDOS41MTU2MiAwLjkyMzA3NyA4Ljc1IDEuNzg4NDYgOC43NSAyLjc2OTIzQzguNzUgMy44MDc2OSA5LjUxNTYyIDQuNjE1MzggMTAuNSA0LjYxNTM4Wk0yNi4yNSAxMC4xNTM4SDE3LjVDMTYuNTE1NiAxMC4xNTM4IDE1Ljc1IDExLjAxOTIgMTUuNzUgMTJDMTUuNzUgMTMuMDM4NSAxNi41MTU2IDEzLjg0NjIgMTcuNSAxMy44NDYySDI2LjI1QzI3LjE3OTcgMTMuODQ2MiAyOCAxMy4wMzg1IDI4IDEyQzI4IDExLjAxOTIgMjcuMTc5NyAxMC4xNTM4IDI2LjI1IDEwLjE1MzhaTTI2LjI1IDE5LjM4NDZIMTcuNUMxNi41MTU2IDE5LjM4NDYgMTUuNzUgMjAuMjUgMTUuNzUgMjEuMjMwOEMxNS43NSAyMi4yNjkyIDE2LjUxNTYgMjMuMDc2OSAxNy41IDIzLjA3NjlIMjYuMjVDMjcuMTc5NyAyMy4wNzY5IDI4IDIyLjI2OTIgMjggMjEuMjMwOEMyOCAyMC4yNSAyNy4xNzk3IDE5LjM4NDYgMjYuMjUgMTkuMzg0NloiIGZpbGw9IiNGRjdBMDAiLz4KPC9zdmc+Cg==");
      width: 28px;
      height: 24px;
    }
  }

  &.user-needs {
    border: 1px solid #FFC839;
    background-color: #FFFCF5;

    .user-needs-icon {
      display: inline-block;
      background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAzMCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0xNC45NTMxIDE1QzE3LjY3MTkgMTUgMTkuODI4MSAxMi44NDM4IDE5LjgyODEgMTAuMTI1QzE5LjgyODEgNy40NTMxMiAxNy42MjUgNS4yNSAxNC45NTMxIDUuMjVDMTIuMjgxMiA1LjI1IDEwLjEyNSA3LjQ1MzEyIDEwLjEyNSAxMC4xMjVDMTAuMDc4MSAxMi44NDM4IDEyLjI4MTIgMTUgMTQuOTUzMSAxNVpNMTcuMjk2OSAxNi41SDEyLjY1NjJDOC45NTMxMiAxNi41IDYgMTkuMzEyNSA2IDIyLjc4MTJDNiAyMy40ODQ0IDYuNTYyNSAyNCA3LjMxMjUgMjRIMjIuNjQwNkMyMy4zOTA2IDI0IDI0IDIzLjQ4NDQgMjQgMjIuNzgxMkMyNCAxOS4zMTI1IDIxIDE2LjUgMTcuMjk2OSAxNi41Wk0yNCA3LjVDMjYuMDYyNSA3LjUgMjcuNzUgNS44NTkzOCAyNy43NSAzLjc1QzI3Ljc1IDEuNjg3NSAyNi4wNjI1IDAgMjQgMEMyMS44OTA2IDAgMjAuMjUgMS42ODc1IDIwLjI1IDMuNzVDMjAuMjUgNS44NTkzOCAyMS44OTA2IDcuNSAyNCA3LjVaTTguNTc4MTIgMTAuMTI1QzguNTc4MTIgOS44OTA2MiA4LjYyNSA5LjY1NjI1IDguNjcxODggOS40MjE4OEM4LjE1NjI1IDkuMTQwNjIgNy41OTM3NSA5IDYuOTg0MzggOUg0LjEyNUMxLjgyODEyIDkgMCAxMC45Njg4IDAgMTMuNDA2MkMwIDEzLjg3NSAwLjMyODEyNSAxNC4yNSAwLjc5Njg3NSAxNC4yNUgxMC4xNzE5QzkuMTg3NSAxMy4xNzE5IDguNTc4MTIgMTEuNzE4OCA4LjU3ODEyIDEwLjEyNVpNNiA3LjVDOC4wNjI1IDcuNSA5Ljc1IDUuODU5MzggOS43NSAzLjc1QzkuNzUgMS42ODc1IDguMDYyNSAwIDYgMEMzLjg5MDYyIDAgMi4yNSAxLjY4NzUgMi4yNSAzLjc1QzIuMjUgNS44NTkzOCAzLjg5MDYyIDcuNSA2IDcuNVpNMjUuODI4MSA5SDIyLjk2ODhDMjIuMzU5NCA5IDIxLjc5NjkgOS4xODc1IDIxLjI4MTIgOS40MjE4OEMyMS4yODEyIDkuNjU2MjUgMjEuMzI4MSA5Ljg5MDYyIDIxLjMyODEgMTAuMTI1QzIxLjMyODEgMTEuNzE4OCAyMC43NjU2IDEzLjE3MTkgMTkuNzgxMiAxNC4yNUgyOS4xNTYyQzI5LjYyNSAxNC4yNSAzMCAxMy44NzUgMzAgMTMuNDA2MkMzMCAxMC45Njg4IDI4LjEyNSA5IDI1LjgyODEgOVoiIGZpbGw9IiNGRkM4MzkiLz4NCjwvc3ZnPg0K");
      width: 30px;
      height: 24px;
    }
  }

  &.solutions {
    border: 1px solid #07A13B;
    background-color: #F3FAF5;

    .solutions-icon {
      display: inline-block;
      background: url("data:image/svg+xml;base64,DQo8c3ZnIHdpZHRoPSIyOCIgaGVpZ2h0PSIyOCIgdmlld0JveD0iMCAwIDI4IDI4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPg0KPHBhdGggZD0iTTI1Ljg3MDggMTEuNDYyNUwyNS4yIDEwLjAwNDJMMjMuNzQxNyA5LjMzMzMzTDI1LjIgOC42NjI1TDI1Ljg3MDggNy4yMDQxN0wyNi41NDE3IDguNjYyNUwyOCA5LjMzMzMzTDI2LjU0MTcgMTAuMDA0MkwyNS44NzA4IDExLjQ2MjVaTTIyLjE2NjcgNi4zNTgzM0wyMS4xNDU4IDQuMkwxOC45ODc1IDMuMTc5MTdMMjEuMTQ1OCAyLjE1ODMzTDIyLjE2NjcgMEwyMy4xODc1IDIuMTU4MzNMMjUuMzQ1OCAzLjE3OTE3TDIzLjE4NzUgNC4yTDIyLjE2NjcgNi4zNTgzM1pNMTAuNSAyNS42NjY3QzkuODM4ODkgMjUuNjY2NyA5LjI3OTg2IDI1LjQzODIgOC44MjI5MiAyNC45ODEyQzguMzY1OTcgMjQuNTI0MyA4LjEzNzUgMjMuOTY1MyA4LjEzNzUgMjMuMzA0MkgxMi44NjI1QzEyLjg2MjUgMjMuOTY1MyAxMi42MzQgMjQuNTI0MyAxMi4xNzcxIDI0Ljk4MTJDMTEuNzIwMSAyNS40MzgyIDExLjE2MTEgMjUuNjY2NyAxMC41IDI1LjY2NjdaTTUuNzc1IDIxLjQ5NThWMTkuNzQ1OEgxNS4yMjVWMjEuNDk1OEg1Ljc3NVpNNS45MjA4MyAxNy45NjY3QzQuNjM3NSAxNy4xMzA2IDMuNjIxNTMgMTYuMDg1NCAyLjg3MjkyIDE0LjgzMTJDMi4xMjQzMSAxMy41NzcxIDEuNzUgMTIuMTYyNSAxLjc1IDEwLjU4NzVDMS43NSA4LjIxNTI4IDIuNjE1MjggNi4xNjM4OSA0LjM0NTgzIDQuNDMzMzNDNi4wNzYzOSAyLjcwMjc4IDguMTI3NzggMS44Mzc1IDEwLjUgMS44Mzc1QzEyLjg3MjIgMS44Mzc1IDE0LjkyMzYgMi43MDI3OCAxNi42NTQyIDQuNDMzMzNDMTguMzg0NyA2LjE2Mzg5IDE5LjI1IDguMjE1MjggMTkuMjUgMTAuNTg3NUMxOS4yNSAxMi4xNjI1IDE4Ljg4MDYgMTMuNTc3MSAxOC4xNDE3IDE0LjgzMTJDMTcuNDAyOCAxNi4wODU0IDE2LjM4MTkgMTcuMTMwNiAxNS4wNzkyIDE3Ljk2NjdINS45MjA4M1pNNi41NjI1IDE2LjIxNjdIMTQuNDY2N0MxNS40IDE1LjU5NDQgMTYuMTM4OSAxNC43ODc1IDE2LjY4MzMgMTMuNzk1OEMxNy4yMjc4IDEyLjgwNDIgMTcuNSAxMS43MzQ3IDE3LjUgMTAuNTg3NUMxNy41IDguNjYyNSAxNi44MTQ2IDcuMDE0NTggMTUuNDQzNyA1LjY0Mzc1QzE0LjA3MjkgNC4yNzI5MiAxMi40MjUgMy41ODc1IDEwLjUgMy41ODc1QzguNTc1IDMuNTg3NSA2LjkyNzA4IDQuMjcyOTIgNS41NTYyNSA1LjY0Mzc1QzQuMTg1NDIgNy4wMTQ1OCAzLjUgOC42NjI1IDMuNSAxMC41ODc1QzMuNSAxMS43MzQ3IDMuNzcyMjIgMTIuODA0MiA0LjMxNjY3IDEzLjc5NThDNC44NjExMSAxNC43ODc1IDUuNjA5NzIgMTUuNTk0NCA2LjU2MjUgMTYuMjE2N1oiIGZpbGw9IiMwN0EyM0IiLz4NCjwvc3ZnPg0K");
      width: 28px;
      height: 28px;
    }
  }
}

.unit-4 {
  .unit-4-icon {
    position: relative;
    display: inline-block;
    vertical-align: middle;

    span {
      font-size: 1.5rem;
      color: $black;
    }

    &:before {
      display: none;
      position: absolute;
      content: "";
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: $black;
      opacity: .2;
      z-index: -1;
      top: 10px;
      left: -20px;
    }
  }

  h3 {
    font-size: 20px;
    color: $black;
  }
}

a.link-primary {
  color: $primary !important;

  &:hover {
    text-decoration: underline;
  }
}

.media-1 {
  position: relative;
  display: block;
  overflow: hidden;
  margin-bottom: 20px;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .8);
    content: "";
    transition: .3s all ease;
    opacity: 0;
    visibility: hidden;
  }

  img {
    object-fit: cover;
    transition: .3s all ease;
  }

  .media-1-content {
    z-index: 4;
    top: 50%;
    position: absolute;
    width: 100%;
    text-align: center;
    transform: translateY(-50%);

    h2 {
      position: relative;
      color: $white;
      font-size: 1.5rem;
      transition: .4s all ease-in-out;
      opacity: 0;
      visibility: hidden;
      bottom: -10px;
      margin-bottom: 0;
    }

    .category {
      position: relative;
      transition: .3s all ease-in-out;
      opacity: 0;
      bottom: -10px;
      visibility: hidden;
      color: darken($white, 40%);
      font-size: 13px;
      text-transform: uppercase;
      letter-spacing: .2em;
    }
  }

  &:hover {
    &:after {
      opacity: 1;
      visibility: visible;
    }

    h2 {
      bottom: 0;
      opacity: 1;
      visibility: visible;
    }

    .category {
      opacity: 1;
      visibility: visible;
      bottom: 0;
      transition-delay: 0.09s;
      // animation-direction: alternate;
    }

    img {
      transform: scale(1.05);
    }
  }
}