.persona-card {
  h4 span {
    font-size: 1rem;
  }

  .subtitle {
    color: rgba($black, .56);
  }
}


.img-sm-fullscreen {
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.img-md-fullscreen {
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

.img-lg-fullscreen {
  @include media-breakpoint-down(lg) {
    width: 100%;
  }
}

.embed-container {
  padding: 56.25% 0 0 0;
  position: relative;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}